import { graphql, useStaticQuery } from "gatsby";

export default function useAnalytics() {
  const { site } = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          ym
        }
      }
    }
  `);

  return {
    reachGoal: (goal: string) => {
      const { ym } = window as any;
      if (ym) {
        ym(site.siteMetadata.ym, "reachGoal", goal);
      }
    },
  };
}
