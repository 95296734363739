import { EventInfo } from "framer-motion";
import React from "react";
import { get } from "../../constants/theme";
import Box from "../Box/Box";
import MotionBox from "../Box/MotionBox";
import { Link } from "gatsby";

type Props = {
  id: string;
  to: string;
  children: React.ReactNode;
  handleHoverStart: (id: string) => void;
  handleHoverEnds: () => void;
  disabled?: boolean;
};

const LinkWrapper = ({
  id,
  to,
  children,
  handleHoverStart,
  handleHoverEnds,
  disabled = false,
}: Props) => {
  const hoverStart = React.useCallback(
    (event: any, info: EventInfo) => {
      handleHoverStart(id);
    },
    [handleHoverStart, id]
  );

  const hoverEnds = React.useCallback(
    (event: any, info: EventInfo) => {
      handleHoverEnds();
    },
    [handleHoverEnds]
  );
  return (
    <MotionBox
      sx={{
        width: "fit-content",
        color: get("palette.text.dark_gold"),
        opacity: disabled ? 0.5 : 1,
      }}
      onHoverStart={hoverStart}
      onHoverEnd={hoverEnds}
      key={id}
      whileHover={{ opacity: 0.6 }}
    >
      <Box
        as={Link}
        to={to}
        sx={{
          display: "block",
          fontSize: ["34px", "34px", "34px", "62px"],
          lineHeight: ["48px", "48px", "48px", "68px"],
          fontFamily: "custom_42263",
          fontFeatureSettings: `"liga" 0, "rlig" 0, "dlig" 0, "hlig" 0, "smcp" 0`,
          textRendering: "optimizeLegibility",
          fontSmooth: "antialiased",
          cursor: "pointer",
          textDecoration: "none",
          background: "none",
          pointerEvents: disabled ? "none" : null,
        }}
      >
        {children}
      </Box>
    </MotionBox>
  );
};

export default React.memo(LinkWrapper);
