import React from "react";
import { GatsbyImage } from "gatsby-plugin-image";
import { get } from "../../../constants/theme";
import Box from "../../Box/Box";
import Input from "../../Input2";
import { FormProvider, useForm } from "react-hook-form";
import { graphql, Link as GatbsyLink, useStaticQuery } from "gatsby";
import { SubscriptionForm } from "../../../types/common/contactForm";
import { FooterQuery } from "../../../types/components/footer";
import Checkmark from "../../../../assets/checkmark.inline.svg";
import { clampBuilder } from "../../../helpers/sizeCalc";
import { useViewportScroll, useTransform } from "framer-motion";

import { FormBtn, SmallTextWrapper, Paragraph2 } from "../../Styled/Text";
import FormRequestSpinner from "../../Styled/FormRequestSpinner";
import { subscribeToNewsletter } from "../../../api/orders";
import { AnimatePresence } from "framer-motion";
import MotionBox from "../../Box/MotionBox";
import useWindowResize from "../../../hooks/useWindowResize";
import { Languages } from "../../../types/common/languages";
import PersonalDataConsent from "../../PersonalDataConsent";
import iconTelegram from "../../../../assets/icon_telegram.svg";
import styled from "styled-components";
import useAnalytics from "../../../hooks/useAnalytics";

interface OrderSumbmisionState {
  isSendingRequest: boolean;
  isErrorOccur: string | null;
  isSucceed: boolean;
}

const SubscribeBtn = styled(FormBtn)`
  font-size: 20px;
  margin-top: 30px;
  padding: 10px;
`;

const ContactP = styled(Paragraph2)`
  line-height: 1.7;
  font-size: 16px;

  @media screen and (min-width: 1366px) {
    line-height: 1.8;
    font-size: 18px;
  }
`;

const Footer = () => {
  const { height } = useWindowResize();
  const { reachGoal } = useAnalytics();

  const [orderSubmissionState, setOrderSubmissionState] =
    React.useState<OrderSumbmisionState>({
      isSendingRequest: false,
      isErrorOccur: null,
      isSucceed: false,
    });

  const { scrollY } = useViewportScroll();

  const arrowHide = useTransform(
    scrollY,
    [0, height / 2 / 2, height],
    ["none", "none", "block"]
  );

  const form = useForm<SubscriptionForm>();

  const {
    register,
    handleSubmit,
    reset,
    watch,
    formState: { errors },
  } = form;

  React.useEffect(() => {
    const formSub = watch(() => {
      orderSubmissionState.isErrorOccur &&
        setOrderSubmissionState((prev) => ({ ...prev, isErrorOccur: null }));
    });

    return () => {
      formSub.unsubscribe();
    };
  }, [watch, orderSubmissionState]);

  const handleScrollTop = React.useCallback(() => {
    if (typeof window === "undefined") return;
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  const { site, translationsJson } = useStaticQuery<FooterQuery>(graphql`
    query FooterQuery {
      site {
        siteMetadata {
          lang
          address
          addressLink
          phone
          email
          social {
            telegram
          }
        }
      }
      translationsJson {
        components {
          layout {
            footer {
              arrow {
                childImageSharp {
                  gatsbyImageData(layout: FULL_WIDTH)
                }
              }
              policyurl
              policytext
              langVersLinks {
                ru
                en
              }
              title
              address
              contact_form {
                inputs {
                  name {
                    id
                    order
                    title
                  }
                  email {
                    id
                    order
                    title
                  }
                }
                submit_btn {
                  title
                }
                errorMessage
              }
              links {
                text
                disabled
                path
              }
            }
          }
        }
      }
    }
  `);

  const submitContactForm = React.useCallback(
    async (formData: SubscriptionForm) => {
      setOrderSubmissionState(() => ({
        isSendingRequest: true,
        isErrorOccur: null,
        isSucceed: false,
      }));
      try {
        await subscribeToNewsletter({
          lang: Languages[site.siteMetadata.lang],
          ...formData,
        });
        reset();
        setOrderSubmissionState(() => ({
          isSendingRequest: false,
          isErrorOccur: null,
          isSucceed: true,
        }));
      } catch (error) {
        setOrderSubmissionState(() => ({
          isSendingRequest: false,
          isErrorOccur: "Unable to create order",
          isSucceed: false,
        }));
        setTimeout(() => {
          if (
            !orderSubmissionState.isSendingRequest ||
            !orderSubmissionState.isSucceed
          )
            setOrderSubmissionState((prev) => ({
              ...prev,
              isErrorOccur: null,
            }));
        }, 3500);
      } finally {
        reachGoal("subscribe_newsletter");
      }
    },
    [reset, orderSubmissionState, site.siteMetadata.lang, reachGoal]
  );

  return (
    <Box
      as="footer"
      sx={{
        position: "relative",
        zIndex: 20,
        backgroundColor: get("palette.background.contact_form"),
        padding: ["40px 27px", "64px 27px", "38px 112px 32px"],
      }}
    >
      <Box
        sx={{
          display: [null, null, "grid"],
          gridTemplateColumns: [null, null, "repeat(12, 1fr)"],
          gap: "0 32px",
        }}
      >
        {/* карта сайта */}
        <Box
          sx={{
            gridColumn: "1 / 4",
            display: "flex",
            width: "100%",
            flexDirection: ["column-reverse", "column-reverse", "row", "row"],
            marginBottom: ["20px", null, null, "30px"],
            "& > :nth-child(1n)": {
              flex: 1,
              justifySelf: "flex-start",
              alignSelf: ["center", "center", "flex-start", "flex-start"],
            },
          }}
        >
          <Box
            sx={{
              display: "flex",
              width: "100%",
              flexDirection: ["column", "column", "row", "row"],
              alignItems: "flex-start",
              gap: [0, 0, "3.5rem", "3.5rem"],
              "& > :nth-child(1n)": {
                justifySelf: "flex-start",
              },
            }}
          >
            <Box
              sx={{
                alignSelf: ["center", "center", "flex-start", "flex-start"],
                width: "fit-content",
                "& > :nth-child(1n)": {
                  textAlign: ["center", "center", "left", "left"],
                },
              }}
            >
              {translationsJson.components.layout.footer.links.map((r, i) => (
                <SmallTextWrapper
                  sx={{
                    opacity: r.disabled ? 0.5 : 1,
                    pointerEvents: r.disabled ? "none" : null,
                    lineHeight: ["1.7 !important", null, null, "2 !important"],
                    fontSize: [
                      "16px !important",
                      null,
                      null,
                      "18px !important",
                    ],
                    textTransform: "none",
                    ":first-letter": {
                      textTransform: "uppercase",
                    },
                  }}
                  as={GatbsyLink}
                  key={i}
                  to={r.path}
                >
                  {r.text}
                </SmallTextWrapper>
              ))}
            </Box>
          </Box>
        </Box>

        {/* форма подписки на рассылку */}
        <Box
          sx={{
            gridColumn: "5 / 9",
            width: "100%",
            maxWidth: ["360px", null, "initial"],
            margin: "0 auto",

            marginBottom: "22px",
          }}
        >
          <Box
            sx={{
              width: "100%",
            }}
          >
            <FormProvider {...form}>
              <Box
                as="form"
                noValidate
                onSubmit={handleSubmit(submitContactForm)}
                sx={{
                  width: "100%",

                  maxWidth: [
                    null,
                    null,
                    "clamp(12.5rem, -1.1451rem + 36.4477vw, 37.125rem)",
                    "clamp(12.5rem, -1.1451rem + 36.4477vw, 37.125rem)",
                  ],
                  color: get("palette.text.dark_gold"),
                  "::placeholder": {
                    color: get("palette.text.dark_gold_disabled"),
                  },
                }}
              >
                <Input
                  autofil={{ bg: get("palette.background.contact_form") }}
                  placeholder={
                    translationsJson.components.layout.footer.contact_form
                      .inputs.name.title
                  }
                  type="text"
                  error={errors.name ? true : false}
                  register={register(
                    translationsJson.components.layout.footer.contact_form
                      .inputs.name.id as "name",
                    {
                      required: true,
                      validate: (v) => {
                        return v.trim().length > 0 ? true : "error";
                      },
                    }
                  )}
                />
                <Input
                  autofil={{ bg: get("palette.background.contact_form") }}
                  placeholder={
                    translationsJson.components.layout.footer.contact_form
                      .inputs.email.title
                  }
                  type="text"
                  error={errors.email ? true : false}
                  register={register(
                    translationsJson.components.layout.footer.contact_form
                      .inputs.email.id as "email",
                    {
                      required: true,
                      pattern:
                        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                    }
                  )}
                />

                <PersonalDataConsent
                  sx={{ marginTop: "24px", fontSize: "12px" }}
                />

                <AnimatePresence exitBeforeEnter>
                  {!orderSubmissionState.isSendingRequest &&
                    !orderSubmissionState.isSucceed &&
                    !orderSubmissionState.isErrorOccur && (
                      <MotionBox
                        key={"sendOrderBtn"}
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          width: "100%",
                        }}
                        initial={{ opacity: 0 }}
                        animate={{
                          opacity: 1,
                          transition: { duration: 1, ease: "easeInOut" },
                        }}
                        exit={{
                          opacity: 0,
                          transition: { duration: 0.5, ease: "linear" },
                        }}
                      >
                        <SubscribeBtn>
                          {`${translationsJson.components.layout.footer.contact_form.submit_btn.title}`}
                        </SubscribeBtn>
                      </MotionBox>
                    )}
                  {orderSubmissionState.isSendingRequest && (
                    <SubscribeBtn
                      as="div"
                      sx={{
                        cursor: "default",
                      }}
                    >
                      <FormRequestSpinner
                        sx={{ margin: "auto" }}
                        color="#fff"
                        bg={get("palette.background.contact_form")}
                      />
                    </SubscribeBtn>
                  )}
                  {orderSubmissionState.isSucceed && (
                    <MotionBox
                      key={"succedCase"}
                      initial={{ opacity: 0 }}
                      animate={{
                        opacity: 1,
                        transition: { duration: 1, ease: "easeInOut" },
                      }}
                      exit={{
                        opacity: 0,
                        transition: { duration: 0.5, ease: "linear" },
                      }}
                    >
                      <SubscribeBtn
                        as="div"
                        sx={{
                          cursor: "default",
                        }}
                      >
                        <Box
                          as={Checkmark}
                          sx={{
                            fill: "rgb(184,171,148)",
                            height: "100%",
                          }}
                        />
                      </SubscribeBtn>
                    </MotionBox>
                  )}
                  {orderSubmissionState.isErrorOccur && (
                    <MotionBox
                      key={"errorCase"}
                      initial={{ opacity: 0, scale: 0.95 }}
                      animate={{
                        scale: 1,
                        opacity: 1,
                        transition: { duration: 1, ease: "easeInOut" },
                      }}
                      exit={{
                        opacity: 0,
                        transition: { duration: 0.5, ease: "linear" },
                      }}
                    >
                      <SubscribeBtn
                        as="div"
                        sx={{
                          cursor: "default",
                          color: get("palette.error"),
                        }}
                      >
                        {`${translationsJson.components.layout.footer.contact_form.errorMessage}`}
                      </SubscribeBtn>
                    </MotionBox>
                  )}
                </AnimatePresence>
              </Box>
            </FormProvider>
          </Box>
        </Box>

        {/* контакты */}
        <Box
          sx={{
            gridColumn: "10 / 13",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            alignSelf: "flex-start",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: ["center", "center", "flex-start", "flex-start"],
              textAlign: ["center", "center", "center", "left"],
              marginBottom: "28px",
              "& > :nth-child(1n)": {
                textAlign: ["center", "center", "left", "left"],
              },
            }}
          >
            {translationsJson.components.layout.footer.address.map((ad, i) => (
              <ContactP
                key={i}
                as="a"
                variant="normal"
                sx={{
                  maxWidth: ["unset", "85%", "unset", "unset"],
                  color: get("palette.text.dark_gold"),
                  opacity: 1,
                }}
                href={`${site.siteMetadata.addressLink}`}
              >
                {ad}
              </ContactP>
            ))}
            <ContactP
              variant="normal"
              as="a"
              sx={{
                color: get("palette.text.dark_gold"),
                opacity: 1,
                marginTop: "20px",
                "&:hover": {
                  opacity: 0.85,
                },
              }}
              href={`mailto:${site.siteMetadata.email}`}
            >
              {site.siteMetadata.email}
            </ContactP>
            <ContactP
              variant="normal"
              as="a"
              sx={{
                color: get("palette.text.dark_gold"),
                opacity: 1,
                "&:hover": {
                  opacity: 0.85,
                },
              }}
              href={`tel:${site.siteMetadata.phone}`}
            >
              {site.siteMetadata.phone}
            </ContactP>
          </Box>
        </Box>
      </Box>

      <Box
        sx={{
          display: [null, null, "grid"],
          gridTemplateColumns: [null, null, "repeat(12, 1fr)"],
          gap: "0 32px",
          marginTop: [null, null, null, "80px"],
        }}
      >
        {/* телеграм */}
        <Box
          sx={{
            gridColumn: "1 / 4",
            alignSelf: "center",
            width: "100%",
            color: get("palette.text.dark_gold"),
            "& > :nth-child(1n)": {
              textAlign: ["center", "center", "left", "left"],
            },
          }}
        >
          <SmallTextWrapper as={"a"} href={site.siteMetadata.social.telegram}>
            <img src={iconTelegram} alt="Telegram" />
          </SmallTextWrapper>
        </Box>

        {/* политика конфиденциальности */}
        <Box
          sx={{
            gridColumn: "5 / 9",
            display: "flex",
            justifySelf: "center",
            alignSelf: "center",
            justifyContent: [
              "center",
              "center",
              "space-between",
              "space-between",
            ],
            flexDirection: "row",
            gap: "2rem",
            color: get("palette.text.dark_gold"),
          }}
        >
          <SmallTextWrapper
            sx={{
              cursor: "pointer",
              ":hover": {
                opacity: 0.65,
              },
              textTransform: "none",
              textDecoration: "underline !important",
              fontSize: ["16px !important", null, null, "18px !important"],
            }}
            as={"a"}
            href={translationsJson.components.layout.footer.policyurl}
          >
            {translationsJson.components.layout.footer.policytext}
          </SmallTextWrapper>
        </Box>

        {/* переключение языков */}
        <Box
          sx={{
            gridColumn: "10/ 13",
            display: "flex",
            justifyContent: "center",
            gap: "1em",
            justifySelf: "end",
            alignSelf: "center",
          }}
        >
          <SmallTextWrapper
            sx={{
              cursor: "pointer",
              ":hover": {
                opacity: 0.65,
              },
              fontSize: ["16px !important", null, null, "18px !important"],
            }}
            as={"a"}
            href={translationsJson.components.layout.footer.langVersLinks.ru}
          >
            RU
          </SmallTextWrapper>
          <SmallTextWrapper
            sx={{
              cursor: "pointer",
              ":hover": {
                opacity: 0.65,
              },
              fontSize: ["16px !important", null, null, "18px !important"],
            }}
            as={"a"}
            href={translationsJson.components.layout.footer.langVersLinks.en}
          >
            EN
          </SmallTextWrapper>
        </Box>
      </Box>

      <MotionBox
        style={{
          display: arrowHide as any,
        }}
      >
        <Box
          as={GatsbyImage}
          sx={{
            bottom: clampBuilder(599, 1680, 0, 2.6875),
            right: clampBuilder(599, 1680, 2, 4.6875),
            position: "fixed",
            width: "50px",
            height: "50px",
            cursor: "pointer",
          }}
          image={
            translationsJson.components.layout.footer.arrow.childImageSharp
              .gatsbyImageData
          }
          alt=""
          onClick={handleScrollTop}
        />
      </MotionBox>
    </Box>
  );
};

export default React.memo(Footer);
